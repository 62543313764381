import React from 'react'
import styled from '@emotion/styled'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import { GridContainer, ContentContainer } from '../../components/Grid'
import { H2, Paragraph } from '../../components/Typography'
import Image from '../../components/Image'
import ButtonLink from '../../components/Buttons'

import { Gutter } from '../../utils/styles'

const StyledContentContainer = styled(ContentContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minHeight: 400,
})

const TextContainer = styled.div({
  margin: `${Gutter.DOUBLE} ${Gutter.FULL}`,
})

const LayoutThankYou = () => (
  <Layout>
    <Section>
      <GridContainer>
        <StyledContentContainer width={[1, 1, 1 / 2]} m="auto">
          <Image
            width={100}
            // eslint-disable-next-line
            src="https://res.cloudinary.com/pulsedatatools/image/upload/v1560951408/pulse-analytics-marketing/logo/pulse-logo-icon-only-blue.svg"
          />
          <TextContainer>
            <H2>Thank you for your submission</H2>
            <Paragraph>
              Our team usually responds to requests for demos within 24 – 48 hours.
            </Paragraph>
          </TextContainer>
          <ButtonLink to="/">Return Home</ButtonLink>
        </StyledContentContainer>
      </GridContainer>
    </Section>
  </Layout>
)

export default LayoutThankYou
